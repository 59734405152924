import { useState, useCallback, Fragment } from 'react'
import { Formik, Field, Form, FieldArray, useFormikContext } from 'formik'
import * as Yup from 'yup'
import get from 'lodash.get'
import { useQuery, useApolloClient, gql, useReactiveVar } from '@apollo/client'
import { useParams, Link, useHistory, Prompt, Redirect } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import NumberFormat from 'react-number-format'
import {
  Button,
  Input,
  FINANCIAL_DEFAULT_PROPS,
  useModal,
  Icon,
  IconButton,
  Dropdown,
  Loader,
  TextArea,
} from '@aider/ui'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import {
  BalanceCell,
  FileInput,
  Attachment,
  LoadingContainer,
} from '@components/'
import {
  useUploadReportAttachment,
  STATISTICS,
  useOnPremiumOrTrial,
} from '@hooks/'
import { activePrincipalIdVar } from '@/cache'
import { openInNewTab } from '@utils/open-in-new-tab'
import { goToStripeCustomerPortal } from '@utils/stripe'

import FormSection from './FormSection'
import FormGroup from './FormGroup'
import AddAccount from './AddAccount'
import AddAsset from './AddAsset'
import AddDebt from './AddDebt'
import ExportReport from './ExportReport'
import SubmitReportToMunicipality from './SubmitReportToMunicipality'
import LockedReport from './LockedReport'
import CreateReport from './CreateReport'

/**
 * Stockholms way of calculating the total difference for a period. Modified to work with negative numbers
 * @see https://godman.stockholm/siteassets/god-man-formyndare-och-forvaltare/blanketter/arsrakning-slutrakningsblankett-tomma-rader-20210122-002.pdf
 *
 * @param  {Number} a Total bank funds at start of period
 * @param  {Number} b Total incomes for the period
 * @param  {Number} c Total expenses for the period
 * @param  {Number} d Total bank funds at end of period
 * @return {Number}   The difference between
 */
const getPeriodDifference = (a, b, c, d) => a + b - Math.abs(c) - d

const repeatableItem = {
  name: Yup.string().required('Fyll i ett giltigt kontonamn'),
  startAmount: Yup.number().nullable(),
  endAmount: Yup.number().nullable(),
}

const validationSchema = Yup.object().shape({
  accounts: Yup.array().of(
    Yup.object().shape({
      ...repeatableItem,
      accountNumber: Yup.string().required('Fyll i ett giltigt kontonummer'),
    }),
  ),
  assets: Yup.array().of(Yup.object().shape(repeatableItem)),
  debts: Yup.array().of(
    Yup.object().shape({
      ...repeatableItem,
      description: Yup.string('Fyll i en giltig beskrivning'),
    }),
  ),
  attachments: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      url: Yup.string(),
      originalFilename: Yup.string().nullable(),
    }),
  ),
})

// Used for note, we don't have unlimited space in the report pdf
const MAX_LENGTH_NOTES = 400

const REPORT = gql`
  query report($reportId: ID!) {
    report(id: $reportId) {
      id
      name
      from
      to
      note
      signed
      duplicate
      attachments {
        id
        filename
        originalFilename
        fileExtension
        url
        attachmentType
      }
      accounts {
        name
        accountNumber
        startAmount
        endAmount
      }
      assets {
        name
        description
        startAmount
        endAmount
        supervised
      }
      debts {
        name
        description
        startAmount
        endAmount
      }
      questionAnswer
    }
  }
`

const UPDATE_REPORT = gql`
  mutation updateReport(
    $reportId: ID!
    $name: String
    $from: Date
    $to: Date
    $note: String
    $accounts: JSON
    $assets: JSON
    $debts: JSON
    $attachmentIds: [ID!]
    $questionAnswer: String
  ) {
    updateReport(
      input: {
        id: $reportId
        name: $name
        from: $from
        to: $to
        note: $note
        accounts: $accounts
        assets: $assets
        debts: $debts
        attachments: { sync: $attachmentIds }
        questionAnswer: $questionAnswer
      }
    ) {
      id
      name
      from
      to
      note
      accounts {
        name
        startAmount
        endAmount
      }
      assets {
        name
        description
        startAmount
        endAmount
        supervised
      }
      debts {
        name
        description
        startAmount
        endAmount
      }
      attachments {
        id
        filename
        originalFilename
        fileExtension
        url
        attachmentType
      }
      questionAnswer
    }
  }
`
const PREVIEW_REPORT = gql`
  query previewReport($reportId: ID!) {
    previewReport(id: $reportId) {
      id
      url
    }
  }
`

const CAN_SUBMIT_REPORT = gql`
  query canSubmitReport($reportId: ID!) {
    canSubmitReport(reportId: $reportId) {
      canSendToSupervision
      supervisionReason
      canSendToPeribon
      peribonReason
      startDate
      endDate
    }
  }
`

const PRINCIPAL_BY_ID = gql`
  query principalById($principalId: ID!) {
    principal(id: $principalId) {
      id
      name
      organization {
        id
        name
        supportedByPeribon
      }
    }
  }
`

const FORM_SECTIONS = [
  {
    title: 'Transaktionskonton',
    description:
      'Lägg upp de bankkonton du har till uppgift att redovisa. Ange bankkontots namn och kontonummer, samt ingående och utgående värden. Uppmärksamma vilka regler som gäller i den kommun du ska redovisa till.',
    id: 'konto-och-tillgangar',
    link: 'http://support.aider.nu/sv/articles/9224126-anvisningar-om-ars-och-slutrakning',
    linkText: 'För mer information klicka här: ',
    linkLabel: 'Anvisningar till års- och sluträkning',
  },
  {
    title: 'Övriga bankkonton och tillgångar',
    description:
      'Redogör för din huvudmans övriga konton och tillgångar. Uppmärksamma vilka regler som gäller i den kommun som du ska redovisa till.',
    id: 'ovriga-tillgangar',
    link: 'http://support.aider.nu/sv/articles/9224126-anvisningar-om-ars-och-slutrakning',
    linkText: 'För mer information klicka här: ',
    linkLabel: 'Anvisningar till års- och sluträkning',
  },
  {
    title: 'Skulder',
    description:
      'Ange din huvudmans skulder. Ingående värde hämtar du från föregående års godkända årsräkning.',
    id: 'skulder',
   
  },
  {
    title: 'Årsräkningens bilagor',
    description:
      'Redovisar du digitalt laddar du här upp de underlag som ska bifogas till din års- eller sluträkning. Namnge dokumenten du laddar upp, så att det framgår vad de innehåller.',
    id: 'bilagor',
    link: 'https://support.aider.nu/sv/articles/8569942-hur-laddar-jag-upp-bilagor-i-rapporter',
    linkText: 'För mer information klicka här: ',
    linkLabel: 'Anvisningar till bilagor',
  },
  {
    title: 'Sammanställning',
    description:
      'Här är en sammanställning av dina siffror, se över så att siffrorna stämmer.',
    id: 'sammanstallning',
  },
  {
    title: 'Övriga upplysningar',
    description:
      'Har du några övriga upplysningar som rör ditt uppdrag så kan du ange det här.',
    id: 'ovriga-upplysningar',
  },
  {
    title: 'Redogörelse med bilagor',
    description:
      'Redovisar du digitalt laddar du här upp de underlag som ska bifogas till din års- eller sluträkning. Namnge dokumenten du laddar upp, så att det framgår vad de innehåller.',
    id: 'Statementbilagor',
    link: 'https://support.aider.nu/sv/articles/8569942-hur-laddar-jag-upp-bilagor-i-rapporter',
    linkText: 'För mer information klicka här: ',
    linkLabel: 'Anvisningar till bilagor',
  },
  {
    title: 'Förhandsgranska',
    description:
      'Nedan visas en förhandsgranskning av din rapport, kolla igenom den så att dina siffror stämmer.',
    id: 'forhandsgranska-din-rapport',
  },

  /* {
    title: 'Frågor från Överförmyndaren',
    description: 'Nedan visas frågor från överförmyndaren',
    id: 'fragor-fran-overformyndaren',
  }, */
]

const FORM_SECTIONS_RESTRICTED = [
  {
    title: 'Signera och skicka in',
    description:
      'För att skicka in din års- eller sluträkning, klicka på Signera och skicka in nedan. Följ anvisningarna och signera med ditt mobila BankID. Rapporten kommer att skickas till överförmyndarkontoret i följande kommun: ',
    id: 'lamna-in',
  },
]

const Report = () => {
  const history = useHistory()
  const { reportId } = useParams()
  const client = useApolloClient()
  const principalId = useReactiveVar(activePrincipalIdVar)

  // We keep track of the selected principal id on first load,
  // so that we can redirect to the reports list when the user
  // changes the active principal. This is needed because the
  // URL at which the report is is still accessible to the logged
  // in user, but does not belong to the newly selected principal.
  const [principalIdOnFirstLoad, _] = useState(principalId)

  const [uploadAttachment, { loading: uploadingAttachment }] =
    useUploadReportAttachment()

  const [savingReport, setSavingReport] = useState(false)
  const [initialValues, setInitialValues] = useState({
    note: '',
    accounts: [],
    assets: [],
    debts: [],
    attachments: [],
    reportName: '',
    questionAnswer: [],
  })
  const [numPages, setNumPages] = useState(null)
  const [initialPageNumber, setInitialPageNumber] = useState(1)
  const [pageNumber, setPageNumber] = useState(1)

  const onDocumentLoadSuccess = pages => {
    setNumPages(pages.numPages)
    setPageNumber(initialPageNumber)
  }

  const changePage = offset => {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  const previousPage = () => changePage(-1)

  const nextPage = () => changePage(1)

  const firstPage = () => setPageNumber(1)

  const lastPage = () => setPageNumber(numPages)

  /**
   * Add Account Modal
   */
  const {
    openModal: openAddAccountModal,
    closeModal: closeAddAccountModal,
    isOpen: addAccountModalIsOpen,
    Modal: AddAccountModal,
  } = useModal()

  /**
   * Add Asset Modal
   */
  const {
    openModal: openAddAssetModal,
    closeModal: closeAddAssetModal,
    isOpen: addAssetModalIsOpen,
    Modal: AddAssetModal,
  } = useModal()

  /**
   * Add Debt Modal
   */
  const {
    openModal: openAddDebtModal,
    closeModal: closeAddDebtModal,
    isOpen: addDebtModalIsOpen,
    Modal: AddDebtModal,
  } = useModal()

  /**
   * Export Modal
   */
  const {
    openModal: openExportModal,
    closeModal: closeExportModal,
    isOpen: exportModalIsOpen,
    Modal: ExportModal,
  } = useModal()

  /**
   * Sign and submit to municipality Modal
   */
  const {
    openModal: openSubmitToMunicipalityModal,
    closeModal: closeSubmitToMunicipalityModal,
    isOpen: submitToMunicipalityModalIsOpen,
    Modal: SubmitReportToMunicipalityModal,
  } = useModal()

  /**
   * Locked report Modal
   */
  const {
    closeModal: closeLockedReportModal,
    isOpen: LockedReportModalIsOpen,
    Modal: LockedReportModal,
  } = useModal({ closeOnClickOutside: false, closeOnEsc: false })

  /**
   * Edit name and date modal
   */
  const {
    openModal: openEditNameAndDateModal,
    closeModal: closeEditNameAndDateModal,
    isOpen: editNameAndDateModalIsOpen,
    Modal: EditNameAndDateModal,
  } = useModal()

  /**
   * Still on trial modal
   * Shown when the user tries to export the report but is still on trial
   */
  const {
    openModal: openOnTrialModal,
    closeModal: closeOnTrialModal,
    isOpen: onTrialModalIsOpen,
    Modal: OnTrialModal,
  } = useModal()

  /**
   * Get the subscription status of the logged in user
   */
  const { onTrial } = useOnPremiumOrTrial()

  /**
   * Get report and update initial form state
   */
  const {
    data: {
      report: {
        name: reportName,
        from: reportFrom,
        to: reportTo,
        duplicate,
        signed: isReportSigned,
      } = {},
    } = {},
    loading: loadingReport,
  } = useQuery(REPORT, {
    variables: { reportId },
    skip: !reportId,
    onCompleted: useCallback(
      ({
        report: {
          signed,
          accounts,
          debts,
          attachments,
          assets,
          note,
          name,
          questionAnswer,
        } = {},
      }) => {
        setInitialValues({
          ...initialValues,
          accounts: accounts || [],
          debts: debts || [],
          attachments: attachments || [],
          assets: assets || [],
          note: note ?? '',
          reportName: name ?? '',
          questionAnswer: JSON.parse(questionAnswer) || [],
        })
        if (signed) {
          // The report is signed, show locked report modal
          // openLockedReportModal()
        }
      },
      [initialValues],
    ),
    onError: useCallback(() => {
      toast.error('Kunde inte hämta rapporten')
    }, []),
  })

  /**
   * Get total incomes and expenses
   */
  const {
    data: {
      principal: { statistics: { totalValue: totalExpenses } = {} } = {},
    } = {},
    loading: loadingTotalExpenses,
  } = useQuery(STATISTICS, {
    fetchPolicy: 'no-cache',
    skip: !principalId || !reportFrom || !reportTo,
    variables: {
      principalId,
      type: 'EXPENSES_BY_CATEGORY',
      date: { from: reportFrom, to: reportTo },
      includeTax: true,
    },
  })

  const {
    data: {
      principal: { statistics: { totalValue: totalIncome } = {} } = {},
    } = {},
    loading: loadingTotalIncome,
  } = useQuery(STATISTICS, {
    fetchPolicy: 'no-cache',
    skip: !principalId || !reportFrom || !reportTo,
    variables: {
      principalId,
      type: 'INCOMES_BY_CATEGORY',
      date: { from: reportFrom, to: reportTo },
      includeTax: true,
    },
  })

  // Get preview pdf data of report
  const {
    data: { previewReport: { url: previewUrl } = {} } = {},
    refetch,
    loading: loadingPreview,
  } = useQuery(PREVIEW_REPORT, {
    fetchPolicy: 'no-cache',
    awaitRefetchQueries: true,
    variables: {
      reportId,
    },
  })

  // Get principal by id
  const {
    data: {
      principal: {
        organization: {
          name: principalMunicipalityName,
          supportedByPeribon,
        } = {},
      } = {},
    } = {},
  } = useQuery(PRINCIPAL_BY_ID, {
    fetchPolicy: 'no-cache',
    skip: !principalId || !reportFrom || !reportTo,
    variables: {
      principalId,
    },
  })

  // Get if report can be submitted to Peribon
  const { data: { canSubmitReport } = {} } = useQuery(CAN_SUBMIT_REPORT, {
    fetchPolicy: 'no-cache',
    awaitRefetchQueries: true,
    variables: {
      reportId,
    },
  })

  const {
    canSendToSupervision,
    peribonReason,
    startDate: suggestedReportStartDate,
    endDate: suggestedReportEndDate,
    loading: loadingCanSendChecks,
  } = canSubmitReport ?? {}
  const canSendToPeribon =
    canSubmitReport?.canSendToPeribon && supportedByPeribon

  /**
   * Handle form submit
   */

  const handleSubmit = async (type, formValues, resetForm) => {
    if (onTrial) {
      openOnTrialModal()
      return
    }

    if (type === 'EXPORT') {
      openExportModal()
    }

    setSavingReport(true)
    try {
      // Update report
      await client.mutate({
        mutation: UPDATE_REPORT,
        variables: {
          reportId,
          accounts: JSON.stringify(formValues.accounts),
          debts: JSON.stringify(formValues.debts),
          attachmentIds: formValues.attachments.map(({ id }) => id),
          assets: JSON.stringify(formValues.assets),
          note: formValues.note,
          questionAnswer: JSON.stringify(formValues.questionAnswer),
        },
      })

      // Refetch pdf data from API
      await refetch()
      setInitialPageNumber(pageNumber)
    } catch (error) {
      toast.error('Kunde inte spara rapporten')
    } finally {
      if (type !== 'EXPORT') {
        toast.success('Rapporten sparades')
      }
      setSavingReport(false)
      // We reset the form so it's not considered dirty
      resetForm({ values: formValues })
    }
  }

  /**
   * Component that triggers an alert if the react router is used to change page
   * while the current form has a dirty state.
   * @returns {Object} An alert asking for confirmation before leaving the page
   */
  const PromptIfDirty = () => {
    const formik = useFormikContext()
    return (
      <Prompt
        when={formik.dirty && formik.submitCount === 0}
        message="Ändringar du har gjort kommer att förloras. Vill du fortsätta?"
      />
    )
  }

  const [showTooltip, setShowTooltip] = useState(false)

  return (
    <LoadingContainer
      loading={loadingReport || loadingTotalExpenses || loadingTotalIncome}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur
      >
        {({
          values,
          setFieldValue,
          handleBlur,
          isValid,
          errors,
          touched,
          dirty,
          resetForm,
        }) => {
          const getFieldError = field =>
            get(touched, field) && get(errors, field)

          // Calculate total ingoing and outgoing value of all accounts and get the difference
          const endBankFunds = values.accounts.reduce(
            (a, item) => a + item.endAmount || 0,
            0,
          )
          const startBankFunds = values.accounts.reduce(
            (a, item) => a + item.startAmount || 0,
            0,
          )
          const difference = getPeriodDifference(
            startBankFunds,
            totalIncome,
            totalExpenses,
            endBankFunds,
          )

          const disableSign =
            !isValid ||
            dirty ||
            savingReport ||
            loadingCanSendChecks ||
            (!canSendToPeribon && !canSendToSupervision)

          return (
            <Form>
              {/* Redirect to the reports list if the user changed principal */}
              {principalId !== principalIdOnFirstLoad && (
                <Redirect to="/huvudman/rapporter" />
              )}

              <PromptIfDirty />
              <header className="flex items-center justify-between py-4 space-x-6 bg-white">
                <div className="flex items-center space-x-3 flex-1">
                  <Link
                    to="/huvudman/rapporter"
                    className="text-blue-500 text-2xl font-medium hover:opacity-50 transition-opacity"
                  >
                    ...
                  </Link>
                  <Icon
                    name="chevron-right"
                    className="flex-shrink-0 w-2.5 h-2.5 text-black fill-current"
                  />
                  <div className="flex flex-col w-full relative">
                    <div className="flex items-center gap-1 w-full relative">
                      <span className="text-xl font-medium">{reportName}</span>
                      {isReportSigned && (
                        <div className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            className="w-5 h-5 mt-0.5 text-blue-500"
                            onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                            />
                          </svg>
                          {showTooltip && (
                            <div className="absolute w-auto bg-gray-100 border border-gray-300 shadow-md left-8 -top-8 px-3 py-0.5 rounded-full">
                              <p className="text-gray-800 font-medium text-sm">
                                This report was signed and submitted
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <span className="text-gray-700">
                      {reportFrom}&ensp;–&ensp;{reportTo}{' '}
                      <span
                        className="pl-4 text-blue-600 font-medium focus:outline-none hover:opacity-75 cursor-pointer transition-opacity duration-200 ease-in-out"
                        onClick={() => openEditNameAndDateModal()}
                      >
                        Ändra
                      </span>
                    </span>
                  </div>
                </div>
                <div className="flex items-center space-x-2">
                  <Button
                    type="button"
                    variant="primary"
                    title="Spara"
                    disabled={!isValid || savingReport}
                    isLoading={savingReport}
                    onClick={() => handleSubmit('SAVE', values, resetForm)}
                  />
                  <Button
                    type="button"
                    variant="primary"
                    title="Skriv ut"
                    disabled={!isValid || savingReport}
                    isLoading={savingReport}
                    onClick={() => handleSubmit('EXPORT', values, resetForm)}
                  />
                  {onTrial && (
                    <div className="relative">
                      <Icon
                        name="diamond"
                        className="w-8 h-8 bg-white -top-7 -left-6 rounded-full absolute text-blue-400 p-1.5 fill-current border border-blue-400"
                      />
                    </div>
                  )}
                </div>
              </header>
              <div className="flex items-start mb-20 gap-8">
                {/* Sidebar */}
                <div className="sticky z-10 top-0 flex flex-col pt-8 flex-0 w-1/3 bg-white">
                  <div className="flex flex-col mb-4 space-y-2">
                    <span className="text-gray-700 text-sm font-medium">
                      Ta mig till:
                    </span>
                    {FORM_SECTIONS.map(({ id, title }) => (
                      <a
                        key={id}
                        href={`#${id}`}
                        className="text-md font-medium"
                      >
                        {title}
                      </a>
                    ))}

                    {supportedByPeribon &&
                      FORM_SECTIONS_RESTRICTED.map(({ id, title }) => (
                        <a key={id} href={`#${id}`} className="font-medium">
                          {title}
                        </a>
                      ))}
                  </div>
                  <div className="flex flex-col">
                    <span className="mb-1 text-gray-700 text-sm font-medium">
                      Utgående behållning :
                    </span>
                    <span>
                      <BalanceCell value={difference} />
                    </span>
                  </div>
                </div>
                {/* Content */}
                <div className="flex flex-col pt-8">
                  {/* Accounts */}
                  <FormSection
                    title={FORM_SECTIONS[0].title}
                    description={FORM_SECTIONS[0].description}
                    link={FORM_SECTIONS[0].link}
                    id={FORM_SECTIONS[0].id}
                    linkText={FORM_SECTIONS[0].linkText}
                    linkLabel={FORM_SECTIONS[0].linkLabel}
                  >
                    {/* Accounts Fields */}
                    <FieldArray name="accounts">
                      {({ remove, push }) => (
                        <Fragment>
                          {values.accounts.map((value, index) => {
                            const field = `accounts.${index}`
                            return (
                              <FormGroup
                                title={value.name}
                                description={value.accountNumber}
                                onDelete={() => remove(index)}
                                key={field}
                              >
                                <div className="flex flex-1">
                                  <Field
                                    {...FINANCIAL_DEFAULT_PROPS}
                                    as={Input}
                                    id={`${field}.startAmount`}
                                    name={`${field}.startAmount`}
                                    error={getFieldError(
                                      `${field}.startAmount`,
                                    )}
                                    label={reportFrom}
                                    placeholder="0 kr"
                                    onValueChange={({ floatValue }) => {
                                      setFieldValue(
                                        `${field}.startAmount`,
                                        floatValue,
                                      )
                                    }}
                                    onBlur={handleBlur}
                                    className="w-full"
                                  />
                                </div>
                                <div className="flex flex-1">
                                  <Field
                                    {...FINANCIAL_DEFAULT_PROPS}
                                    as={Input}
                                    id={`${field}.endAmount`}
                                    name={`${field}.endAmount`}
                                    error={getFieldError(`${field}.endAmount`)}
                                    label={reportTo}
                                    placeholder="0 kr"
                                    onValueChange={({ floatValue }) => {
                                      setFieldValue(
                                        `${field}.endAmount`,
                                        floatValue,
                                      )
                                    }}
                                    onBlur={handleBlur}
                                    className="w-full"
                                  />
                                </div>
                              </FormGroup>
                            )
                          })}

                          {/* Add Account Modal */}
                          <AddAccountModal visible={addAccountModalIsOpen}>
                            <AddAccount
                              onSuccess={submittedAccountValues => {
                                push({
                                  ...submittedAccountValues,
                                  startAmount: null,
                                  endAmount: null,
                                })
                                closeAddAccountModal()
                              }}
                              onCancel={closeAddAccountModal}
                            />
                          </AddAccountModal>
                          <Button
                            type="button"
                            variant="secondary"
                            title="Lägg till konto"
                            onClick={openAddAccountModal}
                          />
                        </Fragment>
                      )}
                    </FieldArray>
                  </FormSection>
                  {/* Assets */}
                  <FormSection
                    title={FORM_SECTIONS[1].title}
                    description={FORM_SECTIONS[1].description}
                    link={FORM_SECTIONS[1].link}
                    id={FORM_SECTIONS[1].id}
                    linkText={FORM_SECTIONS[1].linkText}
                    linkLabel={FORM_SECTIONS[1].linkLabel}
                  >
                    {/* Assets Fields */}
                    <FieldArray name="assets">
                      {({ remove, push }) => (
                        <Fragment>
                          {values.assets.map((value, index) => {
                            const field = `assets.${index}`
                            return (
                              <FormGroup
                                title={value.name}
                                description={value.description}
                                onDelete={() => remove(index)}
                                key={field}
                                supervised={value.supervised}
                              >
                                <div className="flex flex-1">
                                  <Field
                                    {...FINANCIAL_DEFAULT_PROPS}
                                    as={Input}
                                    id={`${field}.startAmount`}
                                    name={`${field}.startAmount`}
                                    error={getFieldError(
                                      `${field}.startAmount`,
                                    )}
                                    label={reportFrom}
                                    placeholder="0 kr"
                                    onValueChange={({ floatValue }) => {
                                      setFieldValue(
                                        `${field}.startAmount`,
                                        floatValue,
                                      )
                                    }}
                                    onBlur={handleBlur}
                                    className="w-full"
                                  />
                                </div>
                                <div className="flex flex-1">
                                  <Field
                                    {...FINANCIAL_DEFAULT_PROPS}
                                    as={Input}
                                    id={`${field}.endAmount`}
                                    name={`${field}.endAmount`}
                                    error={getFieldError(`${field}.endAmount`)}
                                    label={reportTo}
                                    placeholder="0 kr"
                                    onValueChange={({ floatValue }) => {
                                      setFieldValue(
                                        `${field}.endAmount`,
                                        floatValue,
                                      )
                                    }}
                                    onBlur={handleBlur}
                                    className="w-full"
                                  />
                                </div>
                              </FormGroup>
                            )
                          })}

                          {/* Add Asset Modal */}
                          <AddAssetModal visible={addAssetModalIsOpen}>
                            <AddAsset
                              onSuccess={submittedAssetValues => {
                                push({
                                  ...submittedAssetValues,
                                  startAmount: null,
                                  endAmount: null,
                                })
                                closeAddAssetModal()
                              }}
                              onCancel={closeAddAssetModal}
                            />
                          </AddAssetModal>
                          <Button
                            type="button"
                            variant="secondary"
                            title="Lägg till tillgång"
                            onClick={openAddAssetModal}
                          />
                        </Fragment>
                      )}
                    </FieldArray>
                  </FormSection>
                  {/* Debts */}
                  <FormSection
                    title={FORM_SECTIONS[2].title}
                    description={FORM_SECTIONS[2].description}
                    id={FORM_SECTIONS[2].id}
                    linkText={FORM_SECTIONS[2].linkText}
                    linkLabel={FORM_SECTIONS[2].linkLabel}
                  >
                    {/* Debt Fields */}
                    <FieldArray name="debts">
                      {({ remove, push }) => (
                        <Fragment>
                          {values.debts.map((value, index) => {
                            const field = `debts.${index}`
                            return (
                              <FormGroup
                                title={value.name}
                                description={value.description}
                                onDelete={() => remove(index)}
                                key={field}
                              >
                                <div className="flex flex-1">
                                  <Field
                                    {...FINANCIAL_DEFAULT_PROPS}
                                    as={Input}
                                    id={`${field}.startAmount`}
                                    name={`${field}.startAmount`}
                                    error={getFieldError(
                                      `${field}.startAmount`,
                                    )}
                                    label={reportFrom}
                                    placeholder="0 kr"
                                    onValueChange={({ floatValue }) => {
                                      setFieldValue(
                                        `${field}.startAmount`,
                                        floatValue,
                                      )
                                    }}
                                    onBlur={handleBlur}
                                    className="w-full"
                                  />
                                </div>
                                <div className="flex flex-1">
                                  <Field
                                    {...FINANCIAL_DEFAULT_PROPS}
                                    as={Input}
                                    id={`${field}.endAmount`}
                                    name={`${field}.endAmount`}
                                    error={getFieldError(`${field}.endAmount`)}
                                    label={reportTo}
                                    placeholder="0 kr"
                                    onValueChange={({ floatValue }) => {
                                      setFieldValue(
                                        `${field}.endAmount`,
                                        floatValue,
                                      )
                                    }}
                                    onBlur={handleBlur}
                                    className="w-full"
                                  />
                                </div>
                              </FormGroup>
                            )
                          })}

                          {/* Add Debt Modal */}
                          <AddDebtModal visible={addDebtModalIsOpen}>
                            <AddDebt
                              onSuccess={submittedDebtValues => {
                                push({
                                  ...submittedDebtValues,
                                  startAmount: null,
                                  endAmount: null,
                                })
                                closeAddDebtModal()
                              }}
                              onCancel={closeAddDebtModal}
                            />
                          </AddDebtModal>
                          <Button
                            type="button"
                            variant="secondary"
                            title="Lägg till skuld"
                            onClick={openAddDebtModal}
                          />
                        </Fragment>
                      )}
                    </FieldArray>
                  </FormSection>
                  {/* Attachments */}
                  <FormSection
                    title={FORM_SECTIONS[3].title}
                    description={FORM_SECTIONS[3].description}
                    link={FORM_SECTIONS[3].link}
                    id={FORM_SECTIONS[3].id}
                    linkText={FORM_SECTIONS[3].linkText}
                    linkLabel={FORM_SECTIONS[3].linkLabel}
                  >
                    <div className="flex items-center justify-between py-3 border-gray-300">
                      <div className="font-medium">Bifogade filer</div>
                      <FileInput
                        title="Ladda upp"
                        loading={uploadingAttachment}
                        onChange={async e => {
                          try {
                            const file = e.target.files[0]
                            const attachmentType = 'AnnualReport'
                            const {
                              data: { createAttachment: attachment } = {},
                            } = await uploadAttachment({
                              variables: { file, principalId, attachmentType },
                            })

                            const updatedAttachment = {
                              ...attachment,
                              attachmentType,
                            }
                            const updatedAttachments = [
                              ...values.attachments,
                              updatedAttachment,
                            ]

                            setFieldValue('attachments', updatedAttachments)

                            await handleSubmit(
                              'SAVE',
                              { ...values, attachments: updatedAttachments },
                              resetForm,
                            )

                            toast.success('Bilagan laddades upp')
                          } catch (error) {
                            toast.error('Kunde inte ladda upp bilagan')
                          }
                        }}
                      />
                    </div>
                    <ul>
                      {values.attachments
                        ?.filter(
                          ({ attachmentType }) =>
                            attachmentType !== 'Statement',
                        ) 
                        .map(
                          ({
                            id: attachmentId,
                            url,
                            filename,
                            originalFilename,
                            fileExtension,
                          }) => (
                            <li
                              key={attachmentId}
                              className="flex justify-between py-4 border-t border-gray-300"
                            >
                              <Attachment
                                fileType={fileExtension}
                                fileName={originalFilename || filename}
                              />
                              <Dropdown.Root>
                                <Dropdown.Trigger
                                  as={IconButton}
                                  icon={
                                    <Icon name="options" className="w-4 h-4" />
                                  }
                                />
                                <Dropdown.Content>
                                  <Dropdown.Item
                                    onSelect={() => openInNewTab(url)}
                                  >
                                    Visa bilaga
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onSelect={() =>
                                      setFieldValue(
                                        'attachments',
                                        values.attachments.filter(
                                          ({ id }) => id !== attachmentId,
                                        ),
                                      )
                                    }
                                  >
                                    <span className="text-red">Ta bort</span>
                                  </Dropdown.Item>
                                </Dropdown.Content>
                              </Dropdown.Root>
                            </li>
                          ),
                        )}
                    </ul>
                  </FormSection>
                  {/* Form Summary */}
                  <FormSection
                    title={FORM_SECTIONS[4].title}
                    description={FORM_SECTIONS[4].description}
                    id={FORM_SECTIONS[4].id}
                    linkText={FORM_SECTIONS[4].linkText}
                    linkLabel={FORM_SECTIONS[4].linkLabel}
                  >
                    <div className="flex flex-col mb-4 p-3 border border-gray-300 rounded-xl">
                      <div className="flex justify-between mb-3">
                        <span className="font-medium">
                          Bankmedel per {reportFrom} (summa A)
                        </span>
                        <NumberFormat
                          {...FINANCIAL_DEFAULT_PROPS}
                          value={startBankFunds}
                          displayType="text"
                          className="font-medium"
                        />
                      </div>
                      <div className="flex justify-between mb-3">
                        <span className="text-gray-700 italic">
                          Inkomster under perioden (summa B)
                        </span>
                        <NumberFormat
                          {...FINANCIAL_DEFAULT_PROPS}
                          value={totalIncome}
                          displayType="text"
                          className="font-medium"
                        />
                      </div>
                      <div className="flex justify-between mb-3">
                        <span className="text-gray-700 italic">
                          Utgifter under perioden (summa C)
                        </span>
                        <NumberFormat
                          {...FINANCIAL_DEFAULT_PROPS}
                          value={totalExpenses}
                          displayType="text"
                          className="font-medium"
                        />
                      </div>
                      <div className="flex justify-between mb-3">
                        <span className="font-medium">
                          Bankmedel per {reportTo} (summa D)
                        </span>
                        <NumberFormat
                          {...FINANCIAL_DEFAULT_PROPS}
                          value={endBankFunds}
                          displayType="text"
                          className="font-medium"
                        />
                      </div>
                      <div className="flex items-center justify-between pb-1 pt-4 border-t border-gray-300">
                        <span className="font-medium">A + B</span>
                        <NumberFormat
                          {...FINANCIAL_DEFAULT_PROPS}
                          value={startBankFunds + totalIncome}
                          displayType="text"
                          className="font-medium"
                        />
                      </div>
                      <div className="flex items-center justify-between pb-1 pt-4">
                        <span className="font-medium">C + D</span>
                        <NumberFormat
                          {...FINANCIAL_DEFAULT_PROPS}
                          value={endBankFunds + Math.abs(totalExpenses)}
                          displayType="text"
                          className="font-medium"
                        />
                      </div>
                      <div className="flex items-center justify-between pb-1 border-t pt-4 mt-4 border-gray-300">
                        <span className="font-medium">
                          Differens (A + B = C + D)
                        </span>
                        <BalanceCell value={difference} />
                      </div>
                    </div>
                  </FormSection>
                  {/* Report Preview */}
                  <FormSection
                    title={FORM_SECTIONS[5].title}
                    description={FORM_SECTIONS[5].description}
                    id={FORM_SECTIONS[5].id}
                    linkText={FORM_SECTIONS[5].linkText}
                    linkLabel={FORM_SECTIONS[5].linkLabel}
                  >
                    <TextArea
                      id="note"
                      name="note"
                      rows={5}
                      placeholder="Övriga upplysningar"
                      value={values.note}
                      error={touched.note && errors.note}
                      onChange={event =>
                        setFieldValue(
                          'note',
                          event.target.value.slice(0, MAX_LENGTH_NOTES),
                        )
                      }
                    />
                    <p>
                      {values.note.length}/{MAX_LENGTH_NOTES}
                    </p>
                  </FormSection>
                  {/*
                  <FormSection
                    title={FORM_SECTIONS[6].title}
                    description={FORM_SECTIONS[6].description}
                    id={FORM_SECTIONS[6].id}
                  >
                    {values.questionAnswer?.map(
                      ({
                        question,
                        answer,
                        type,
                        booleanValue,
                        questionId,
                      }) => (
                        <div key={questionId} className="flex flex-col mb-4">
                          <label className="font-medium" htmlFor={question}>
                            {question}
                          </label>
                          {type === 'boolean' ? (
                            <div className="flex space-x-2">
                              <input
                                type="radio"
                                id={`${questionId}_ja`}
                                name={questionId}
                                checked={booleanValue === true}
                                onChange={() =>
                                  setFieldValue(
                                    'questionAnswer',
                                    values.questionAnswer.map(item =>
                                      item.questionId === questionId
                                        ? { ...item, booleanValue: true }
                                        : item,
                                    ),
                                  )
                                }
                              />
                              <label
                                htmlFor={`${questionId}_ja`}
                                className="mr-2"
                              >
                                Ja
                              </label>
                              <input
                                type="radio"
                                id={`${questionId}_nej`}
                                name={questionId}
                                checked={booleanValue === false}
                                onChange={() =>
                                  setFieldValue(
                                    'questionAnswer',
                                    values.questionAnswer.map(item =>
                                      item.questionId === questionId
                                        ? { ...item, booleanValue: false }
                                        : item,
                                    ),
                                  )
                                }
                              />
                              <label htmlFor={`${questionId}_nej`}>Nej</label>
                            </div>
                          ) : (
                            <TextArea
                              id={questionId}
                              name={questionId}
                              rows={3}
                              placeholder="Ditt svar här"
                              value={answer}
                              onChange={event => {
                                const newAnswer = event.target.value
                                setFieldValue(
                                  'questionAnswer',
                                  values.questionAnswer.map(item =>
                                    item.questionId === questionId
                                      ? { ...item, answer: newAnswer }
                                      : item,
                                  ),
                                )
                              }}
                            />
                          )}
                        </div>
                      ),
                    )}
                  </FormSection>
                  */}
                  <FormSection
                    title={FORM_SECTIONS[6].title}
                    description={FORM_SECTIONS[6].description}
                    link={FORM_SECTIONS[6].link}
                    id={FORM_SECTIONS[6].id}
                    linkText={FORM_SECTIONS[6].linkText}
                    linkLabel={FORM_SECTIONS[6].linkLabel}
                  >
                    <div className="flex items-center justify-between py-3 border-gray-300">
                      <div className="font-medium">Bifogade filer</div>
                      <FileInput
                        title="Ladda upp"
                        loading={uploadingAttachment}
                        onChange={async e => {
                          try {
                            const file = e.target.files[0]
                            const attachmentType = 'Statement'

                            const {
                              data: { createAttachment: attachment } = {},
                            } = await uploadAttachment({
                              variables: { file, principalId, attachmentType },
                            })

                            const updatedAttachment = {
                              ...attachment,
                              attachmentType,
                            }
                            const updatedAttachments = [
                              ...values.attachments,
                              updatedAttachment,
                            ]

                            setFieldValue('attachments', updatedAttachments)

                            await handleSubmit(
                              'SAVE',
                              { ...values, attachments: updatedAttachments },
                              resetForm,
                            )

                            toast.success('Bilagan laddades upp')
                          } catch (error) {
                            toast.error('Kunde inte ladda upp bilagan')
                          }
                        }}
                      />
                    </div>
                    <ul>
                      {values.attachments
                        ?.filter(
                          ({ attachmentType }) =>
                            attachmentType === 'Statement',
                        )
                        .map(
                          ({
                            id: attachmentId,
                            url,
                            filename,
                            originalFilename,
                            fileExtension,
                          }) => (
                            <li
                              key={attachmentId}
                              className="flex justify-between py-4 border-t border-gray-300"
                            >
                              <Attachment
                                fileType={fileExtension}
                                fileName={originalFilename || filename}
                              />
                              <Dropdown.Root>
                                <Dropdown.Trigger
                                  as={IconButton}
                                  icon={
                                    <Icon name="options" className="w-4 h-4" />
                                  }
                                />
                                <Dropdown.Content>
                                  <Dropdown.Item
                                    onSelect={() => openInNewTab(url)}
                                  >
                                    Öppna
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onSelect={() =>
                                      setFieldValue(
                                        'attachments',
                                        values.attachments.filter(
                                          ({ id }) => id !== attachmentId,
                                        ),
                                      )
                                    }
                                  >
                                    <span className="text-red">Ta bort</span>
                                  </Dropdown.Item>
                                </Dropdown.Content>
                              </Dropdown.Root>
                            </li>
                          ),
                        )}
                    </ul>
                  </FormSection>
                  {/* Report Preview */}
                  <FormSection
                    title={FORM_SECTIONS[7].title}
                    description={FORM_SECTIONS[7].description}
                    id={FORM_SECTIONS[7].id}
                    linkText={FORM_SECTIONS[7].linkText}
                    linkLabel={FORM_SECTIONS[7].linkLabel}
                  >
                    <div className="flex relative">
                      {!savingReport && dirty && (
                        <div className="absolute flex justify-center items-center h-full z-10 w-full flex-col">
                          <div
                            className="p-6 bg-white rounded-lg border border-gray-300"
                            style={{ width: 480 }}
                          >
                            <header className="mb-4 text-black text-lg font-medium">
                              Spara för att se förhandsgranskning
                            </header>
                            <Button
                              variant="secondary"
                              type="button"
                              title="Spara"
                              onClick={() =>
                                handleSubmit('SAVE', values, resetForm)
                              }
                            />
                          </div>
                        </div>
                      )}
                      {savingReport || loadingPreview ? (
                        <div className="flex justify-center items-center">
                          <Loader />
                        </div>
                      ) : (
                        <div
                          className={`${
                            dirty ? 'filter blur-lg' : null
                          } flex flex-col flex-grow mb-4 p-3 border border-gray-300`}
                        >
                          <div className="flex flex-row flex-grow justify-center items-center">
                            <div>
                              <button
                                type="button"
                                text="last page"
                                disabled={pageNumber <= 1}
                                onClick={firstPage}
                                className="pr-2"
                              >
                                <span className="text-black text-sm font-medium">
                                  Start sida
                                </span>
                              </button>
                            </div>
                            <div className="mx-2 border-l border-gray-300 h-full" />
                            <div>
                              <button
                                type="button"
                                disabled={pageNumber <= 1}
                                onClick={previousPage}
                                className="pr-2"
                              >
                                <Icon
                                  name="chevron-left"
                                  className="flex-shrink-0 w-2.5 h-2.5 text-black fill-current"
                                />
                              </button>
                            </div>
                            <div>
                              Sida {pageNumber || (numPages ? 1 : '--')} av{' '}
                              {numPages || '--'}
                            </div>
                            <div>
                              <button
                                type="button"
                                disabled={pageNumber >= numPages}
                                onClick={nextPage}
                                className="pl-2"
                              >
                                <Icon
                                  name="chevron-right"
                                  className="flex-shrink-0 w-2.5 h-2.5 text-black fill-current"
                                />
                              </button>
                            </div>
                            <div className="mx-2 border-l border-gray-300 h-full" />
                            <div>
                              <button
                                type="button"
                                disabled={pageNumber >= numPages}
                                onClick={lastPage}
                                className="pl-2"
                              >
                                <span className="text-black text-sm font-medium">
                                  Sista sida
                                </span>
                              </button>
                            </div>
                          </div>

                          <Document
                            loading={null}
                            onLoadSuccess={onDocumentLoadSuccess}
                            noData="Förhandsgranskning saknas"
                            error={null}
                            file={previewUrl}
                          >
                            <Page
                              width={756}
                              pageNumber={pageNumber}
                              loading={null}
                            />
                          </Document>
                          <div className="flex flex-row flex-grow justify-center items-center">
                            <div>
                              <button
                                type="button"
                                disabled={pageNumber <= 1}
                                onClick={previousPage}
                                className="pr-2"
                              >
                                <Icon
                                  name="chevron-left"
                                  className="flex-shrink-0 w-2.5 h-2.5 text-black fill-current"
                                />
                              </button>
                            </div>
                            <div>
                              Sida {pageNumber || (numPages ? 1 : '--')} av{' '}
                              {numPages || '--'}
                            </div>
                            <div>
                              <button
                                type="button"
                                disabled={pageNumber >= numPages}
                                onClick={nextPage}
                                className="pl-2"
                              >
                                <Icon
                                  name="chevron-right"
                                  className="flex-shrink-0 w-2.5 h-2.5 text-black fill-current"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </FormSection>
                  <FormSection
                    title={FORM_SECTIONS_RESTRICTED[0].title}
                    description={
                      FORM_SECTIONS_RESTRICTED[0].description +
                      principalMunicipalityName
                    }
                    id={FORM_SECTIONS_RESTRICTED[0].id}
                  >
                    {duplicate && (
                      <div className="pb-6">
                        <p>
                          <i>
                            Du har redan skickat in en rapport för perioden{' '}
                            {reportFrom} - {reportTo} så du bör endast skicka in
                            en ny om Överförmyndaren har begärt det.
                          </i>
                        </p>
                        <p className="pt-6">
                          <i>
                            Du kan däremot spara och exportera den för utskrift.
                          </i>
                        </p>
                      </div>
                    )}
                    {false && (
                      <div className="pb-6">
                        <p>
                          <i>
                            För att kunna skicka in rapporten till
                            Överförmyndaren krävs att alla transaktioner under
                            perioden är bokförda.
                          </i>
                        </p>
                      </div>
                    )}

                    {!canSendToPeribon && !canSendToSupervision && (
                      <div className="pb-6">
                        <p>
                          <i>Du kan inte skicka in rapporten.</i>
                        </p>
                      </div>
                    )}

                    {!canSendToSupervision &&
                    !canSendToPeribon &&
                    peribonReason ? (
                      <Fragment>
                        {peribonReason === 'NO_MATCHING_PERIOD' && (
                          <div className="pb-6">
                            <p>
                              <i>
                                Det finns inga redovisningsperioder i
                                Överförmyndarsystemet som matchar tidsperioden
                                för rapporten.
                              </i>
                            </p>
                          </div>
                        )}
                        {peribonReason === 'NO_MATCHING_PERIOD' &&
                          suggestedReportStartDate &&
                          suggestedReportEndDate && (
                            <div className="pb-6">
                              <p>
                                <i>
                                  I Överförmyndarsystemet finns en
                                  redovisningsperiod upplagd med datumen{' '}
                                  {suggestedReportStartDate} till{' '}
                                  {suggestedReportEndDate}.
                                </i>
                              </p>
                            </div>
                          )}
                        {peribonReason === 'CANNOT_SUBMIT_ADJUSTMENT' && (
                          <div className="pb-6">
                            <p>
                              <i>
                                Överförmyndaren har inte begärt in
                                komplettering.
                              </i>
                            </p>
                          </div>
                        )}
                      </Fragment>
                    ) : null}
                    <Button
                      type="button"
                      variant="secondary"
                      title="Signera och skicka in"
                      disabled={disableSign}
                      isLoading={savingReport}
                      onClick={openSubmitToMunicipalityModal}
                    />
                  </FormSection>
                  <div className="flex items-center justify-end space-x-2 fixed bottom-0 bg-white w-full border-red border-bottom">
                    <Button
                      type="button"
                      variant="secondary"
                      title="Spara"
                      disabled={!isValid || savingReport}
                      isLoading={savingReport}
                      onClick={() => handleSubmit('SAVE', values, resetForm)}
                    />
                    <Button
                      type="button"
                      variant="primary"
                      title="Spara & Exportera"
                      disabled={!isValid || savingReport}
                      isLoading={savingReport}
                      onClick={() => handleSubmit('EXPORT', values, resetForm)}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>

      {/* onTrialModal */}
      <OnTrialModal visible={onTrialModalIsOpen}>
        <div className="flex flex-1 flex-col justify-between p-6 bg-white rounded-lg overflow-hidden max-w-md">
          <p className="font-semibold text-lg mb-2">
            Du hittade precis en Premium-funktion
          </p>
          <p className="font-medium text-gray-600">
            För att kunna exportera en rapport så måste du ha en aktiv
            prenumeration av Aider. Provperioden inkluderar inte exporting av
            rapport.
          </p>
          <div className="flex flex-row items-center justify-between mt-4">
            <Button
              type="button"
              title="Stäng"
              variant="secondary"
              onClick={() => closeOnTrialModal()}
            />
            <Button
              type="button"
              title="Skaffa premium"
              variant="primary"
              onClick={() => goToStripeCustomerPortal()}
            />
          </div>
        </div>
      </OnTrialModal>

      {/* Edit name and date Modal */}
      <EditNameAndDateModal visible={editNameAndDateModalIsOpen}>
        <CreateReport
          mode="edit"
          report={{
            id: reportId,
            name: reportName,
            from: reportFrom,
            to: reportTo,
          }}
          onCancel={() => closeEditNameAndDateModal()}
          onError={() => toast.error('Kunde inte spara rapporten')}
          onSuccess={() => {
            closeEditNameAndDateModal()
            refetch()
          }}
        />
      </EditNameAndDateModal>

      {/* Export Modal */}
      <ExportModal visible={exportModalIsOpen}>
        <ExportReport onSuccess={closeExportModal} reportId={reportId} />
      </ExportModal>
      {/* SubmitReportToMunicipality Modal */}
      <SubmitReportToMunicipalityModal
        visible={submitToMunicipalityModalIsOpen}
      >
        <SubmitReportToMunicipality
          canSendToPeribon={canSendToPeribon}
          canSendToSupervision={canSendToSupervision}
          reportId={reportId}
          onSuccess={async () => {
            closeSubmitToMunicipalityModal()
            toast.success(
              `Rapport inskickad till ${principalMunicipalityName} kommun`,
              { duration: 8000 },
            )
            await client.refetchQueries({
              include: ['reports'],
            })
            history.goBack()
          }}
          onError={() => {
            closeSubmitToMunicipalityModal()
            toast.error(
              'Kunde inte skicka in rapporten, var god försök igen vid ett senare tillfälle.',
            )
          }}
          onCancel={() => {
            closeSubmitToMunicipalityModal()
            toast.success('Du avbröt signeringen')
          }}
        />
      </SubmitReportToMunicipalityModal>
      {/* Locked report modal */}
      <LockedReportModal visible={LockedReportModalIsOpen}>
        <LockedReport
          reportId={reportId}
          onSuccess={() => history.push('/huvudman/rapporter')}
          onError={() => {
            closeLockedReportModal()
          }}
          onCancel={closeLockedReportModal}
        />
      </LockedReportModal>
    </LoadingContainer>
  )
}

export default Report
