const FormSection = ({ title, description, link, linkText, linkLabel, id, children }) => (
  <div className="mb-12 pb-8 border-b border-gray-300">
    <header className="flex flex-col mb-4">
      <span className="text-lg font-medium" id={id}>
        {title}
      </span>
      <span
        className="text-gray-700"
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {link && linkText && linkLabel && (
        <span className="text-gray-700">
          {linkText}{' '}
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 font-bold bg-yellow-200 p-1 rounded focus:outline-none hover:opacity-75 cursor-pointer transition-opacity duration-200 ease-in-out text-MD"
          >
            {linkLabel}
          </a>
        </span>
      )}
    </header>
    <div>{children}</div>
  </div>
);

export default FormSection;
