import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useQuery, useApolloClient, gql, useReactiveVar } from '@apollo/client'
import { motion, AnimatePresence } from 'framer-motion'
import {
  ContentState,
  convertToRaw,
  EditorState,
  convertFromRaw,
  Modifier,
} from 'draft-js'
import { format } from 'date-fns'
import {
  Button,
  Input,
  Select,
  Datepicker,
  DatepickerInput,
  Switch,
  RichEditor,
} from '@aider/ui'
import { toast } from 'react-hot-toast'

import { track, events } from '@utils/analytics'
import { activePrincipalIdVar } from '@/cache'

// It has been requested to remove limitations for the
// amount of minutes that can be logged in a single entry.
// Since I don't know the other impacts of this change, I'm
// going to leave this here for now.
// const MAX_MINUTES = 7200
// const MIN_MINUTES = 1

const validationSchema = Yup.object().shape({
  category: Yup.object()
    .shape({
      value: Yup.string().required('Välj en kategori i listan'),
      label: Yup.string().required(),
    })
    .required('Obligatoriskt fält.'),
  // This rule does not work anymore with the rich text editor
  // We default to the category name as a workaround so
  // that value for this field is never empty.
  // description: Yup.string().required('Obligatoriskt fält.'),
  date: Yup.date().required('Obligatoriskt fält'),
  minutes: Yup.number()
    // .min(MIN_MINUTES, 'Ange ett nummer mellan 1 och 7200.')
    // .max(MAX_MINUTES, 'Ange ett nummer mellan 1 och 7200.')
    .required('Obligatoriskt fält.'),
  drivingLog: Yup.boolean().required(),
  fromAddress: Yup.string().when('drivingLog', {
    is: true,
    then: Yup.string().required('Ange en giltig adress'),
    otherwise: Yup.string().nullable(),
  }),
  toAddress: Yup.string().when('drivingLog', {
    is: true,
    then: Yup.string().required('Ange en giltig adress'),
    otherwise: Yup.string().nullable(),
  }),
  distance: Yup.number().when('drivingLog', {
    is: true,
    then: Yup.number().required('Ange ett värde i kilometer').min(0).max(99999),
    otherwise: Yup.number().nullable(),
  }),
})

const JOURNAL_ENTRY = gql`
  query journalEntry($journalEntryId: ID!) {
    journalEntry(id: $journalEntryId) {
      id
      description
      minutes
      date
      journalEntryCategory {
        id
        name
      }
      drivingLogEntry {
        id
        fromAddress
        toAddress
        kilometers
      }
    }
  }
`

const JOURNAL_ENTRY_CATEGORIES = gql`
  query journalEntryCategories {
    journalEntryCategories {
      id
      name
    }
  }
`

const UPSERT_JOURNAL_ENTRY = gql`
  mutation upsertJournalEntry(
    $journalEntryId: ID
    $principalId: ID!
    $categoryId: ID!
    $description: JSON!
    $date: Date!
    $minutes: Int!
  ) {
    upsertJournalEntry(
      id: $journalEntryId
      principalId: $principalId
      journalEntryCategoryId: $categoryId
      description: $description
      date: $date
      minutes: $minutes
    ) {
      id
      drivingLogEntry {
        id
      }
    }
  }
`

const UPSERT_DRIVING_LOG_ENTRY = gql`
  mutation upsertDrivingLogEntry(
    $drivingLogEntryId: ID
    $journalEntryId: ID!
    $fromAddress: String!
    $toAddress: String!
    $distance: Float!
  ) {
    upsertDrivingLogEntry(
      input: {
        id: $drivingLogEntryId
        journalEntryId: $journalEntryId
        fromAddress: $fromAddress
        toAddress: $toAddress
        kilometers: $distance
      }
    ) {
      id
      fromAddress
      toAddress
      kilometers
    }
  }
`

const DELETE_DRIVING_LOG_ENTRY = gql`
  mutation deleteDrivingLogEntry($drivingLogEntryId: ID!) {
    deleteDrivingLogEntry(id: $drivingLogEntryId) {
      id
    }
  }
`

const regex = /^\s*(?=.*[0-9])\d*(?:\.\d{1})?\s*$/
const matchesDistanceFormat = str => regex.test(str)

const CreateJournalEntry = ({ onSuccess, onCancel, journalEntryId }) => {
  const client = useApolloClient()
  const principalId = useReactiveVar(activePrincipalIdVar)
  const [initialValues, setInitialValues] = useState({
    category: null,
    description: '',
    date: null,
    minutes: 1,
    drivingLog: false,
    fromAddress: '',
    toAddress: '',
    distance: '',
  })
  // Rich editor state initialisation,
  // see https://draftjs.org/docs/api-reference-data-conversion/
  // The state is reset when the data is fetched so that we fill
  // in the existing data in the editor.
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  // Handles when a chunk of text is pasted into the rich editor,
  // see https://github.com/facebook/draft-js/issues/728
  // and fixed with https://github.com/facebook/draft-js/issues/416#issuecomment-221639163
  function handlePastedText(text) {
    const blockMap = ContentState.createFromText(text.trim()).blockMap
    const newState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      blockMap,
    )
    setEditorState(EditorState.push(editorState, newState, 'insert-fragment'))
    return true
  }

  /**
   * Get journal entry categories
   */
  const {
    data: { journalEntryCategories: categories = [] } = {},
    loading: loadingCategories,
  } = useQuery(JOURNAL_ENTRY_CATEGORIES, {
    onError: useCallback(() => {
      toast.error('Kunde inte hämta kategorier')
    }, []),
  })

  /**
   * Get existing journal entry a journalEntryId is passed and update form values
   */
  const { loading: loadingJournalEntry } = useQuery(JOURNAL_ENTRY, {
    variables: { journalEntryId },
    skip: !journalEntryId,
    onCompleted: useCallback(
      ({
        journalEntry: {
          journalEntryCategory: category,
          description,
          date,
          minutes,
          drivingLogEntry,
        } = {},
      }) => {
        // Update initial form state
        setInitialValues({
          category: { value: category.id, label: category.name },
          date: new Date(date),
          minutes,
          drivingLog: !!drivingLogEntry,
          fromAddress: drivingLogEntry?.fromAddress || '',
          toAddress: drivingLogEntry?.toAddress || '',
          distance: drivingLogEntry?.kilometers || '',
        })
        // Init the rich text editor
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(JSON.parse(description)),
          ),
        )
      },
      [],
    ),
  })

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useFormik({
    validateOnMount: true,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: async ({
      category,
      date,
      minutes,
      drivingLog,
      fromAddress,
      toAddress,
      distance,
    }) => {
      try {
        const contentState = editorState.getCurrentContent()
        const stringifiedDescription = JSON.stringify(
          convertToRaw(contentState),
        )

        // Upsert JournalEntry
        const { data: { upsertJournalEntry } = {} } = await client.mutate({
          mutation: UPSERT_JOURNAL_ENTRY,
          awaitRefetchQueries: true,
          refetchQueries: ['journalEntries'],
          variables: {
            principalId,
            description: stringifiedDescription,
            categoryId: category.value,
            date: format(date, 'yyyy-MM-dd'),
            minutes,
            journalEntryId,
          },
        })

        track(events.USER_CREATED_JOURNAL_ENTRY)

        // If drivingLog is checked, upsert DrivingLogEntry and update cache
        const drivingLogEntryId = upsertJournalEntry?.drivingLogEntry?.id
        const upsertedJournalEntryId = upsertJournalEntry?.id

        if (drivingLog) {
          await client.mutate({
            mutation: UPSERT_DRIVING_LOG_ENTRY,
            variables: {
              drivingLogEntryId,
              journalEntryId: upsertedJournalEntryId,
              fromAddress,
              toAddress,
              distance: parseFloat(distance),
            },
            update(cache, { data: { upsertDrivingLogEntry } = {} } = {}) {
              cache.modify({
                id: cache.identify(upsertJournalEntry),
                fields: {
                  drivingLogEntry() {
                    const drivingLogEntryRef = cache.writeFragment({
                      data: upsertDrivingLogEntry,
                      fragment: gql`
                        fragment NewDrivingLogEntry on JournalEntry {
                          id
                          fromAddress
                          toAddress
                          kilometers
                        }
                      `,
                    })
                    return drivingLogEntryRef
                  },
                },
              })
            },
          })

          track(events.USER_CREATED_DRIVING_LOG_ENTRY)
          // If drivingLog is not checked and a previous DrivingLogEntry exists, delete it and update cache
        } else if (drivingLogEntryId) {
          await client.mutate({
            mutation: DELETE_DRIVING_LOG_ENTRY,
            variables: { drivingLogEntryId },
            update(cache, { data: { deleteDrivingLogEntry } = {} } = {}) {
              cache.evict({ id: cache.identify(deleteDrivingLogEntry) })
            },
          })
        }

        if (typeof onSuccess === 'function') {
          onSuccess()
        }
      } catch (error) {
        toast.error('Kunde inte skapa inlägget')
      }
    },
  })

  return (
    <div className="bg-white rounded-lg" style={{ width: 900 }}>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="w-full p-8">
          <div className="mb-4 grid grid-cols-3 gap-4">
            <Select
              id="category"
              name="category"
              label="Kategori"
              placeholder="Välj kategori"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.category}
              error={errors.category?.value}
              isLoading={loadingCategories || loadingJournalEntry}
              options={categories.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
            />
            <Datepicker
              id="date"
              name="date"
              label="Datum"
              selected={values.date}
              onChange={d => setFieldValue('date', d)}
              customInput={
                <DatepickerInput
                  id="date"
                  label="Välj datum"
                  startDate={values.date}
                />
              }
            />
            <Input
              id="minutes"
              name="minutes"
              type="number"
              //   max={480}
              min={1}
              step={1}
              label="Tidsåtgång i minuter"
              value={values.minutes}
              error={touched.minutes && errors.minutes}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mr-1 mb-2">
              <span className="inline-block">Anteckning</span>
            </label>
            <RichEditor
              editorState={editorState}
              onChange={setEditorState}
              handlePastedText={handlePastedText}
              id="description"
              name="description"
              maxHeight="30vh"
              stripPastedStyles
              spellcheck
            />
          </div>
        </div>
        <div className="flex flex-col p-8 bg-gray-200 border-t border-b border-gray-300">
          <div className="flex items-center justify-between">
            <label htmlFor="drivingLog" className="font-medium">
              Körjournal
            </label>
            <Switch
              id="drivingLog"
              name="drivingLog"
              onCheckedChange={handleChange}
              checked={values.drivingLog}
            />
          </div>
          <AnimatePresence exitBeforeEnter initial={false}>
            {values.drivingLog ? (
              <motion.div
                initial={{ opacity: 0, x: 25 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 25 }}
                transition={{ duration: 0.15 }}
                key="content"
              >
                <div className="grid grid-cols-3 mt-6 gap-4">
                  <Input
                    id="fromAddress"
                    name="fromAddress"
                    type="input"
                    label="Från"
                    value={values.fromAddress}
                    error={touched.fromAddress && errors.fromAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Input
                    id="toAddress"
                    name="toAddress"
                    type="input"
                    label="Till"
                    value={values.toAddress}
                    error={touched.toAddress && errors.toAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Input
                    id="distance"
                    name="distance"
                    type="number"
                    label="Total resväg i kilometer"
                    value={values.distance}
                    error={touched.distance && errors.distance}
                    onChange={e => {
                      const value = e.target.value
                      if (!value || matchesDistanceFormat(value)) {
                        setFieldValue('distance', value)
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </div>
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, x: -25 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -25 }}
                transition={{ duration: 0.15 }}
                key="empty-state"
                className="flex flex-1 flex-col items-start justify-start mt-2"
              >
                {/* <div className="mb-2 px-1.5 py-0.5 text-white text-xs font-semibold bg-blue-500 rounded uppercase">
                  Nytt
                </div> */}
                <div className="text-center text-gray-500 font-medium text-base">
                  Nu kan du lägga till körningar till dina inlägg.
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <div className="flex justify-between px-8 py-4">
          <Button
            variant="tertiary"
            type="button"
            title="Avbryt"
            onClick={() => typeof onCancel === 'function' && onCancel()}
          />
          <Button
            title="Spara"
            type="submit"
            disabled={!isValid || isSubmitting}
            isLoading={isSubmitting}
            variant="primary"
          />
        </div>
      </form>
    </div>
  )
}

CreateJournalEntry.defaultProps = {
  journalEntryId: '',
}

CreateJournalEntry.propTypes = {
  journalEntryId: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CreateJournalEntry
