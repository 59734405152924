import { useEffect } from 'react'
import { useMutation, gql } from '@apollo/client'
import PropTypes from 'prop-types'
import { Button } from '@aider/ui'

const CREATE_PERSONAL_DATA_EXPORT = gql`
  mutation createPersonalDataExport {
    createPersonalDataExport
  }
`

const ExportAccount = ({ onCancel, email }) => {
  const [createPersonalDataExport] = useMutation(CREATE_PERSONAL_DATA_EXPORT)

  useEffect(() => {
    createPersonalDataExport()
  }, [createPersonalDataExport])

  return (
    <div
      className="flex flex-1 flex-col justify-between p-6 bg-white rounded-lg overflow-hidden"
      style={{ width: 420 }}
    >
      <header className="mb-4 text-center text-black text-lg font-medium">
        Din dataexport är på väg!
      </header>
      <div className="mb-6 text-center">
        Vi sammanställer din data. Det kan ta upp till 30 minuter. Så fort
        exporten är klar mailar vi den till dig på{' '}
        <span className="font-medium">{email}</span>.
      </div>
      <Button
        type="button"
        title="Stäng"
        variant="primary"
        onClick={onCancel}
      />
    </div>
  )
}

ExportAccount.propTypes = {
  onCancel: PropTypes.func.isRequired,
}

export default ExportAccount
