import { useCallback, useEffect, useState } from 'react'
import { useQuery, gql, useLazyQuery, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Button,
  TextArea,
  Input,
  Select,
  Checkbox,
  RadioGroup,
} from '@aider/ui'
import { configureScope } from '@sentry/react'

const GET_USER = gql`
  query getUser {
    me {
      id
      phoneNumber
    }
  }
`

const PERIBON_SUPPORTED_MUNICIPALITIES = gql`
  query peribonSupportedMunicipalities {
    peribonSupportedMunicipalities {
      associatedMunicipalities {
        municipalityName
        municipalityCode
        installationName
        organization {
          id
          name
        }
      }
      municipalities {
        municipalityName
        municipalityCode
        installationName
        organization {
          id
          name
        }
      }
    }
  }
`

const PERIBON_MUNICIPALITY_COMPETENCES = gql`
  query peribonMunicipalityCompetences($municipalityCode: String!) {
    peribonMunicipalityCompetences(municipalityCode: $municipalityCode) {
      competencyType
      competency
    }
  }
`

const UPDATE_USER = gql`
  mutation updateUser($userId: ID!, $phoneNumber: String!) {
    updateUser(id: $userId, phoneNumber: $phoneNumber) {
      id
      phoneNumber
    }
  }
`

const validationSchema = Yup.object().shape({
  organization: Yup.object()
    .shape({
      value: Yup.string().required('Välj en kommun i listan'),
      label: Yup.string().required(),
    })
    .required('Obligatoriskt fält.'),
  title: Yup.string().required('Obligatoriskt fält.'),
  missingPhone: Yup.boolean(),
  phone: Yup.string().when('missingPhone', {
    is: true,
    then: Yup.string().required('Obligatoriskt fält.'),
  }),
  background: Yup.string().required('Obligatoriskt fält.'),
  educated: Yup.string().required('Du måste välja en.'),
  aptitudeTest: Yup.bool().oneOf([true], 'Du måste godkänna.'),
  confirmation: Yup.bool().oneOf([true], 'Du måste godkänna.'),
  competences: Yup.array().of(
    Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required('Välj en kommun i listan'),
        label: Yup.string().required(),
        type: Yup.string().required(),
      }),
    ),
  ),
})

const RegistrationOfInterest = ({ onCancel, submitForm, creatingTicket }) => {
  const [initialValues, setInitialValues] = useState({
    organization: null,
    title: 'Intresseanmälan',
    missingPhone: true,
    phone: '',
    background: '',
    educated: false,
    aptitudeTest: false,
    confirmation: false,
    competences: [],
  })
  // Get current authenticated user
  const { data: { me: { id: userId } = {} } = {} } = useQuery(
    GET_USER,
    {
      onCompleted: useCallback(({ me: { phoneNumber: phone } = {} } = {}) => {
        setInitialValues({
          ...initialValues,
          phone: phone || '',
          missingPhone: phone === null,
        })
      }, []),
    },
    [],
  )

  const {
    loading,
    data: {
      peribonSupportedMunicipalities: {
        associatedMunicipalities = [],
        municipalities = [],
      } = {},
    } = {},
  } = useQuery(PERIBON_SUPPORTED_MUNICIPALITIES)

  const [
    getCompetences,
    { data: { peribonMunicipalityCompetences = [] } = {} },
  ] = useLazyQuery(PERIBON_MUNICIPALITY_COMPETENCES)

  const [updateUser] = useMutation(UPDATE_USER)

  const questions = [
    {
      type: 'textarea',
      name: 'background',
      label: 'Allmän bakgrund/erfarenhet/sysselsättning',
      value: null,
    },
    {
      type: 'radio',
      name: 'educated',
      label:
        'Har du tidigare gått en introduktionskurs för gode män eller förvaltare?',
      value: null,
    },
    {
      type: 'checkbox',
      name: 'aptitudeTest',
      label:
        'Jag är medveten att det ingår i Överförmyndarens lämplighetsprövning att kontrollera att jag inte har någon kriminell belastning, inte förekommer i socialregistret eller inte har några betalningsanmärkningar och jag medger att de kontrolleras hos Kronofogdemyndigheten, Socialnämnden och i Belastningsregistret och kreditupplysningsföretag.',
      value: null,
    },
    {
      type: 'checkbox',
      name: 'confirmation',
      label:
        'Härmed anmäler jag mitt intresse att – efter förfrågan i varje enskilt fall – åta mig uppdrag som ställföreträdare.',
      value: null,
    },
  ]

  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    handleSubmit,
    handleBlur,
    isSubmitting,
    isValid,
    setFieldValue,
  } = useFormik({
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    initialValues,
    onSubmit: async formData => {
      // TODO Get current users assignments
      questions[0].value = formData.background
      questions[1].value = formData.educated === 'true' ? 'Ja' : 'Nej'
      questions[2].value = formData.aptitudeTest ? 'Ja' : 'Nej'
      questions[3].value = formData.confirmation ? 'Ja' : 'Nej'
      // Add competences to questions
      questions[4] = values.competences

      // Store phone in user
      await updateUser({
        variables: { userId, phoneNumber: formData.phone },
      })

      await submitForm({
        title: `${formData.title} till ${values?.organization?.label ?? ''}`,
        formData: questions,
        organization: formData.organization,
      })
    },
  })

  useEffect(() => {
    if (values?.organization?.value) {
      getCompetences({
        variables: { municipalityCode: values.organization.municipalityCode },
      })
    }
  }, [values.organization])

  const groupedOptions = [
    {
      label: 'Kommuner som du har en koppling till',
      options: associatedMunicipalities.map(
        ({ municipalityName, municipalityCode, organization = {} }) => ({
          value: organization?.id || null,
          label: municipalityName,
          municipalityCode,
        }),
      ),
    },
    {
      label: 'Övriga kommuner',
      options: municipalities.map(
        ({ municipalityName, municipalityCode, organization = {} }) => ({
          value: organization?.id || municipalityCode,
          label: municipalityName,
          municipalityCode,
        }),
      ),
    },
  ]

  return (
    <>
      <p className="pb-4">
        Hit vänder du dig när du vill ta emot fler eller ditt första uppdrag:
        Skicka in en intresseanmälan till de kommuner som du vill ta emot
        uppdrag från.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="overflow-y-auto" style={{ maxHeight: '50vh' }}>
          <div className="mb-3">
            <Select
              id="organization"
              name="organization"
              label="Välj kommun"
              placeholder="Välj kommun"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.organization}
              error={errors.organization?.value}
              isLoading={loading}
              options={groupedOptions}
            />
          </div>
          <div className="mb-3">
            <Input
              id="title"
              name="title"
              type="text"
              label="Rubrik"
              value={values.title}
              error={touched.title && errors.title}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </div>

          {values.missingPhone && (
            <div className="mb-3">
              <Input
                id="phone"
                name="phone"
                type="text"
                label="Telefonnummer"
                value={values.phone}
                error={touched.phone && errors.phone}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          )}

          <div className="mb-3">
            <TextArea
              id={questions[0].name}
              name={questions[0].name}
              rows={2}
              label={questions[0].label}
              value={values.background}
              error={touched.background && errors.background}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="mb-3">
            <label
              className="text-base font-medium"
              htmlFor={questions[1].name}
            >
              {questions[1].label}
            </label>
            <RadioGroup.Root
              name={questions[1].name}
              className="flex flex-col space-y-2 pt-2"
              defaultValue={values.educated}
              onValueChange={v => setFieldValue('educated', v)}
            >
              <label htmlFor="true" className="flex items-center space-x-1">
                <RadioGroup.Item value="true" id="true">
                  <RadioGroup.Indicator />
                </RadioGroup.Item>
                <span>Ja</span>
              </label>

              <label htmlFor="false" className="flex items-center space-x-1">
                <RadioGroup.Item value="false" id="false">
                  <RadioGroup.Indicator />
                </RadioGroup.Item>
                <span>Nej</span>
              </label>
            </RadioGroup.Root>
          </div>

          <div className="mb-3">
            <div className="flex items-center space-x-2 mt-4">
              <Checkbox
                id={questions[2].name}
                checked={values.aptitudeTest}
                onCheckedChange={handleChange}
                name={questions[2].name}
              />
              <label
                htmlFor={questions[2].name}
                className="text-sm font-medium w-5/6"
              >
                {questions[2].label}
              </label>
            </div>
          </div>

          <div className="mb-6">
            <div className="flex items-center space-x-2 mt-6">
              <Checkbox
                id={questions[3].name}
                checked={values.confirmation}
                onCheckedChange={handleChange}
                name={questions[3].name}
              />
              <label
                className="text-sm font-medium"
                htmlFor={questions[3].name}
              >
                {questions[3].label}
              </label>
            </div>
          </div>

          {peribonMunicipalityCompetences.length > 0 && (
            <div>
              <div className="mb-6">
                <span className="text-xl">Kompetenser</span>
                <p>
                  Nedan fyller du i vilka kompetenser du besitter och eventuellt
                  vilka typer av uppdrag du är intresserad av.
                </p>
              </div>
              {peribonMunicipalityCompetences.map((item, index) => (
                <div
                  className={
                    peribonMunicipalityCompetences.length - 1 === index
                      ? 'mb-44'
                      : 'mb-6'
                  }
                >
                  <Select
                    key={item[0].competencyType}
                    id={item[0].competencyType}
                    name={`competences.${index}`}
                    label={item[0].competencyType}
                    placeholder={item[0].competencyType}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.competences?.value}
                    isLoading={loading}
                    options={item.map(i => ({
                      value: i.competency,
                      label: i.competency,
                      type: i.competencyType,
                    }))}
                    isMulti
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        <Checkbox
          className="hidden"
          id="missingPhone"
          name="missingPhone"
          onCheckedChange={handleChange}
          onBlur={handleBlur}
          label="Missing phone"
          checked={values.phone === ''}
        />

        <div className="flex justify-between pt-12">
          <Button
            variant="secondary"
            type="button"
            title="Avbryt"
            onClick={() => typeof onCancel === 'function' && onCancel()}
          />
          <Button
            title="Skicka"
            type="submit"
            disabled={!isValid || !dirty}
            isLoading={creatingTicket || isSubmitting}
            variant="primary"
          />
        </div>
      </form>
    </>
  )
}

export default RegistrationOfInterest
