import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useLazyQuery, gql } from '@apollo/client'
import get from 'lodash.get'
import { Loader, Button, Input, Select } from '@aider/ui'

const FIND_PERSON = gql`
  query findPerson($pin: String!) {
    roaringPerson(pin: $pin) {
      posts {
        personalNumber
        address {
          nationalRegistrationAddress {
            registrationDate
            postalNumber
            city
            deliveryAddress2
          }
        }
        details {
          firstName
          givenName
          surName
        }
      }
    }
  }
`

const ALL_ORGANIZATIONS = gql`
  query allOrganizations {
    organizations(
      municipalityCodeLargerThan: "0008" # Larger than 0008, removes any test muncipalities from the list
      orderBy: { column: NAME, order: ASC }
    ) {
      id
      name
      municipalityCode
    }
  }
`

/**
 * A hook that is used to find a person based on a Swedish PIN.
 *
 * @return {Object} Object containing a findPerson method, error, loading and data.
 */
export const useFindPerson = () => {
  // Internal loading state to avoid flash of incorrect data when
  // the data has loaded, but we haven't parsed it yet.
  const [_loading, setLoading] = useState(false)
  const [data, setData] = useState(null)

  const [_findPerson, { error, loading }] = useLazyQuery(FIND_PERSON, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: useCallback(({ roaringPerson: { posts = [] } = {} }) => {
      if (!posts.length) {
        setData(null)
        setLoading(false)
        return
      }

      const firstName = get(posts, '[0].details[0].firstName', '')
      const surName = get(posts, '[0].details[0].surName', '')
      const address = get(
        posts,
        '[0].address.nationalRegistrationAddress[0].deliveryAddress2',
        '',
      )
      const zipCode = get(
        posts,
        '[0].address.nationalRegistrationAddress[0].postalNumber',
        '',
      )
      const city = get(
        posts,
        '[0].address.nationalRegistrationAddress[0].city',
        '',
      )

      setData({
        firstName,
        surName,
        address,
        zipCode,
        city,
      })

      setLoading(false)
    }, []),
    onError: useCallback(() => {
      if (_loading) {
        setLoading(false)
      }
    }, [_loading]),
  })

  const findPerson = useCallback(
    (pin = '') => {
      setLoading(true)
      _findPerson({ variables: { pin } })
    },
    [_findPerson],
  )

  const { data: { organizations = [] } = {} } = useQuery(ALL_ORGANIZATIONS)

  const organizationOptions = organizations.map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  return {
    data,
    error,
    loading: loading || _loading,
    findPerson,
    organizationOptions,
  }
}

/**
 * Render a styled Preview of the data returned from the useFindPerson hook.
 *
 * @param  {Boolean}  props.invalidPin A boolean indicating if the pin is valid or not
 * @param  {Object}   props.error      An error object, if the request failed for some reason
 * @param  {Boolean}  props.loading    A boolean indicating if the request is loading
 * @param  {Object}   props.data       The cleaned up data from the response to roaring
 * @return {Component}
 */
export const Preview = ({
  invalidPin,
  error,
  loading,
  data,
  organizationOptions,
  handleChange,
  handleBlur,
  touched,
  errors,
  values,
  setFieldValue,
}) => {
  if (invalidPin) {
    return (
      <div className="flex flex-col items-center justify-center px-4 h-32 bg-gray-200 border border-dashed border-gray-400 rounded-lg">
        <span className="text-gray-800 text-base">Ange ett personnummer</span>
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center px-4 h-32 bg-gray-200 border border-dashed border-gray-400 rounded-lg rounded-lg">
        <span className="text-sm font-medium">Kunde inte kontakta Aider</span>
        <span className="text-gray-800 text-sm">
          Försök igen om en liten stund.
        </span>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center px-4 h-32 bg-gray-200 border border-dashed border-gray-400 rounded-lg">
        <Loader />
      </div>
    )
  }

  if (!data) {
    if (values.skipRoaring) {
      return (
        <div className="flex flex-col items-center pt-2 h-48">
          <div className="mb-4 w-full">
            <Input
              name="name"
              id="name"
              label="För- och efternamn*"
              type="text"
              value={values.name}
              error={touched.name && errors.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4 w-full">
            <Select
              id="organization"
              name="organization"
              value={values.organization}
              error={errors.organization?.value}
              label="Kommun*"
              placeholder="Välj eller sök efter kommun"
              options={organizationOptions}
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
      )
    }

    return (
      <div className="flex flex-col items-center justify-center px-4 h-32 bg-gray-200 border border-dashed border-gray-400 rounded-lg">
        <span className="text-sm font-medium">Registrera personnummer</span>
        <span className="text-gray-800 text-sm">
          Säkerställ att du angett rätt personnummer, då du inte kan ändra det i
          efterhand.
        </span>
        <div className="mt-4">
          <Button
            title="Registrera"
            name="skipRoaring"
            id="skipRoaring"
            onClick={() => setFieldValue('skipRoaring', true)}
          />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col justify-center px-4 h-32 bg-white border border-gray-300 rounded-lg shadow-sm">
      <div className="mb-3 w-full">
        <div className="text-gray-700 text-sm">Namn</div>
        <div className="font-medium">
          {data.firstName} {data.surName}
        </div>
      </div>
      <div className="flex space-x-3">
        <div className="w-1/2">
          <div className="text-gray-700 text-sm">Adress</div>
          <div className="font-medium truncate">{data.address}</div>
        </div>
        <div className="w-1/2">
          <div className="text-gray-700 text-sm">Stad</div>
          <div className="font-medium truncate">
            {data.zipCode} {data.city}
          </div>
        </div>
      </div>
    </div>
  )
}

Preview.defaultProps = {
  error: null,
  data: null,
}

Preview.propTypes = {
  invalidPin: PropTypes.bool.isRequired,
  error: PropTypes.shape({}),
  loading: PropTypes.bool.isRequired,
  data: PropTypes.shape({}),
}
