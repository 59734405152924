import { gql, useMutation } from '@apollo/client';

const UPLOAD_ATTACHMENT = gql`
  mutation uploadAttachment($file: Upload!, $principalId: ID!) {
    createAttachment(input: { principalId: $principalId, file: $file }) {
      id
      filename
      originalFilename
      fileExtension
      url
    }
  }
`;

const UPLOAD_REPORT_ATTACHMENT = gql`
  mutation uploadReportAttachment($file: Upload!, $principalId: ID!, $attachmentType: String) {
    createAttachment(input: { principalId: $principalId, file: $file, attachmentType: $attachmentType }) {
      id
      filename
      originalFilename
      fileExtension
      url
      attachmentType
    }
  }
`;

export const useUploadAttachment = options => {
  const [
    upload,
    { data: { createAttachment: attachment } = {}, error, loading },
  ] = useMutation(UPLOAD_ATTACHMENT, { ...options });

  return [upload, { attachment, error, loading }];
};

export const useUploadReportAttachment = options => {
  const [
    uploadReport,
    { data: { createAttachment: reportAttachment } = {}, error, loading },
  ] = useMutation(UPLOAD_REPORT_ATTACHMENT, { ...options });

  return [uploadReport, { reportAttachment, error, loading }];
};
